<template>
  <div>
    <el-form label-position="right">
      <div class="add-global-variable-container">
        <div  class="add-global-variable-container-left-side">
          <div class="form-left">
            <h4
              class="h4 f-weight-500 text-left primary-details-title"
            >
              Primary Details
            </h4>

            <el-form-item label="Field Title">
              <el-input
                v-model="field.label"
                placeholder="Enter field title"
              ></el-input>
              <p  v-if="field.label && !field.label.length" class="error"> *Title is required*</p>
            </el-form-item>

            <el-form-item label="Description">
              <el-input
                type="textarea"
                v-model="field.description"
                placeholder="Enter description here"
                :rows="2"
              ></el-input>
            </el-form-item>

            <placeholder :field="field" />

            <field-filled-by :field="field" />

            <hr class="hr-for-mobile" />
          </div>
        </div>

        <div v-loading="loading" class="add-global-variable-container-right-side">
          <el-row :gutter="25">
            <el-col>
              <div class="form-group">
                <el-form-item label="Vairable Type">
                  <el-select
                    v-model="field.global_variable_type"
                    :default-first-option="true"
                    :filterable="true"
                    @change="onChangeGlobalVariableType"
                  >
                    <el-option
                      v-for="(type, index) of globalVariableTypes"
                      :key="index"
                      :label="type"
                      :value="type"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>

            <!-- if Variable type is users then display the following block *** start-->
            <el-col :span="12" v-if="field.global_variable_type == 'USERS'">
              <div class="form-group">
                <el-form-item label="User Type">
                  <el-select
                    v-model="field.global_variable_user_type"
                    :default-first-option="true"
                    :filterable="true"
                    :no-data-text="'No User Types'"
                  >
                    <el-option
                      v-for="(userType, index) of getValidUserTypes"
                      :key="index"
                      :label="userType.name"
                      :value="userType._id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>

            <!-- if Variable type is users then display the following block *** end-->

            <!-- if Variable type is entity then display the following block *** start-->

            <el-col :span="6" v-if="field.global_variable_type == 'ENTITY'">
              <div class="form-group">
                <el-form-item label="Entities">
                  <el-select
                    v-model="field.global_variable_entity_type"
                    :default-first-option="true"
                    :filterable="true"
                    :no-data-text="'No Entities'"
                    @change="setEntityFields($event,false)"
                  >
                    <el-option
                      v-for="(entity, index) of getValidEntities"
                      :key="index"
                      :label="entity.name"
                      :value="entity._id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>

            <el-col :span="6" v-if="field.global_variable_type == 'ENTITY'">
              <div class="form-group">
                <el-form-item label="Selection Type">
                  <el-select
                    v-model="field.global_variable_entity_select_type"
                    :default-first-option="true"
                    :filterable="true"
                    :no-data-text="'No Data'"
                  >
                    <el-option
                      v-for="(item, index) of selectTypeOptions"
                      :key="index"
                      :label="item.name"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>

            <el-col :span="6" v-if="field.global_variable_type == 'ENTITY'">
              <div class="form-group">
                <el-form-item label="Add New Entity Data">
                  <el-select v-model="field.allow_new_entity_data">
                    <el-option :value="true" label="Allowed"></el-option>

                    <el-option :value="false" label="Not Allowed"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>

            <!-- if Variable type is users then display the following block *** end-->
          </el-row>

          <el-divider></el-divider>

          <el-row :gutter="25">
            <el-col :span="6">
              <div class="form-group">
                <div v-if="isEntity && field.global_variable_type == 'ENTITY'">
                  <el-checkbox v-model="field.relationship_field"
                    >Add Relationship</el-checkbox
                  >
                </div>
              </div>
            </el-col>

            <el-col :span="6" v-if="field.relationship_field">
              <div class="form-group">
                <div>
                  <el-form-item label="Target Entity Type">
                    <el-select
                      v-model="field.target_relationship_selection_type"
                      :default-first-option="true"
                      :filterable="true"
                      :no-data-text="'No Data'"
                    >
                      <el-option
                        v-for="(item, index) of selectTypeOptions"
                        :key="index"
                        :label="item.name"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
            </el-col>

            <el-col :span="6" v-if="field.relationship_field">
              <div class="form-group">
                <el-form-item label="Representation">
                  <el-select
                    v-model="field.target_relationship_representation"
                    @change="onChangeRepresentation"
                  >
                    <el-option value="TAB" label="TAB"></el-option>

                    <el-option value="FIELD" label="FIELD"></el-option>

                    <el-option
                      value="UNI-DIRECTION"
                      label="UNI-DIRECTION"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
          </el-row>

          <hr class="hr-for-non-mobile"/>

          <div v-if="field.global_variable_type == 'ENTITY'">
              <el-row   v-if="field.global_variable_type == 'ENTITY' &&
                this.selectedEntityFields.length
              ">
              <el-col>
                <el-button @click="addFilter" class="btnFloat"
                  >ADD FILTERS</el-button
                >
              </el-col>
            </el-row>

            <el-row
              :gutter="25"
              v-if="
                field.global_variable_type == 'ENTITY' &&
                this.selectedEntityFields.length
              "
            >
              <div
                class="vue-data-table-default mw-100"
                v-loading="pageLoading"
              >
                <!--  v-model="data.field" -->

                <el-table :data="Data" style="width: 100%">
                  <el-table-column label="Field" width="150">
                    <template slot-scope="scope">
                      <el-form-item>
                        <el-select
                          v-model="scope.row.field"
                          :disabled="!field.global_variable_entity_type"
                          :no-data-text="'No variables available'"
                          filterable
                          default-first-option
                          @change="setFilterField($event, scope.$index)"
                        >
                   
                          <el-option
                            v-for="(field, index) of scope.row.fieldList"
                            v-bind:key="index"
                            :value="field.key"
                            :label="field.label"
                          >
                            {{ field.label }}
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </template>
                  </el-table-column>

                  <el-table-column label="Filter" width="150">
                    <template slot-scope="scope">
                      <el-form-item>
                        <el-select
                          v-model="scope.row.filter"
                          :disabled="!scope.row.field"
                          :no-data-text="'No Options available'"
                          filterable
                          default-first-option
                          @change="setFilter"
                        >
                          <el-option
                            v-for="(filter, index) of scope.row.filterOptions"
                            :key="index"
                            :value="filter"
                            :label="filter"
                            >{{ filter }}</el-option
                          >
                        </el-select>
                      </el-form-item>
                    </template>
                  </el-table-column>

                  <el-table-column label="Options" width="150">
                    <template slot-scope="scope">
                      <el-form-item>
                        <el-select
                          v-model="scope.row.option"
                          :disabled="!scope.row.filter"
                          :no-data-text="'No Options available'"
                          filterable
                          default-first-option
                        >
                          <el-option
                            v-for="(filterVal, index) of scope.row.optionsList"
                            :key="index"
                            :value="filterVal"
                            :label="filterVal"
                            >{{ filterVal }}</el-option
                          >
                        </el-select>
                      </el-form-item>
                    </template>
                  </el-table-column>

                  <el-table-column label="Value Type" width="150">
                    <template slot-scope="scope">
                      <el-form-item>
                        <el-select
                          v-model="scope.row.valueType"
                          :disabled="!scope.row.filterOptions"
                          :no-data-text="'No Options available'"
                          filterable
                          default-first-option
                          @change="setValueType($event, scope.$index)"
                        >
                          <el-option
                            v-for="(item, index) of scope.row.valueTypeList"
                            :key="index"
                            :value="item.id"
                            :label="item.name"
                            >{{ item.name }}</el-option
                          >
                        </el-select>
                      </el-form-item>
                    </template>
                  </el-table-column>

                  <el-table-column label="Value" width="150">
                    <template slot-scope="scope">
                      <el-form-item
                        v-if="scope.row.valueType == 'manual_field'"
                      >
                        <el-input
                          v-if="scope.row.valueInputType == 'input'"
                          placeholder="Value"
                          :disabled="!scope.row.filter"
                          v-model="scope.row.value"
                        ></el-input>
                        <el-date-picker
                          v-if="scope.row.valueInputType == 'date'"
                          v-model="scope.row.value"
                          type="datetime"
                          placeholder="Select date and time"
                          :disabled="!scope.row.filter"
                        ></el-date-picker>
                        <el-select
                          v-model="scope.row.value"
                          placeholder="Select"
                          v-if="scope.row.valueInputType == 'select'"
                          :disabled="!scope.row.filter"
                        >
                          <el-option
                            v-for="item in selectValueOptions"
                            :key="item"
                            :label="item"
                            :value="item"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item
                        v-if="scope.row.valueType == 'global_variables'"
                      >
                        <el-select
                          v-model="scope.row.value"
                          placeholder="Select"
                          v-if="
                            scope.row.valueType == 'global_variables' &&
                            scope.row.selectedVariableType != 'SELECT'
                          "
                          :disabled="!scope.row.filter"
                          filterable
                        >
                          <el-option
                            v-for="(item, index) of getGlobalVariableData()"
                            :key="index"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>

                        <el-col :span="4">
                          <el-form-item
                            label="SELECT FIELD"
                            v-if="
                              scope.row.valueType == 'global_variables' &&
                              selectedVariableType == 'SELECT'
                            "
                          >
                            <el-select
                              v-model="globalVariableSelectFilterValue"
                              placeholder="Select"
                              v-if="
                                scope.row.valueType == 'global_variables' &&
                                selectedVariableType == 'SELECT'
                              "
                              :disabled="!selectedFilter"
                              filterable
                            >
                              <el-option
                                v-for="(item, index) of getGlobalVariableData()"
                                :key="index"
                                :label="item.label"
                                :value="item._id"
                              ></el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item
                            label="Options"
                            v-if="globalVariableSelectFilterValue"
                          >
                            <el-select
                              v-model="filterValue"
                              placeholder="Select"
                              v-if="globalVariableSelectFilterValue"
                              :disabled="!selectedFilter"
                              filterable
                            >
                              <el-option
                                v-for="(
                                  item, index
                                ) of getGlobalVariableSelectDataOptions()"
                                :key="index"
                                :label="item"
                                :value="item"
                              ></el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                      </el-form-item>
                    </template>
                  </el-table-column>

                  <el-table-column label="Actions" width="86">
                    <template slot-scope="scope">
                      <el-form-item>
                        <span>
                          <el-button
                            @click.native.prevent="
                              deleteRow(scope.$index, scope.row)
                            "
                          >
                            <i class="el-icon-delete"></i>
                          </el-button>
                        </span>
                      </el-form-item>
                    </template>
                  </el-table-column>

           
             
                </el-table>
              </div>
                   
                <el-form-item>
                  <el-button
                   
                    @click="saveFilter"
                    type="success"
                    >Save Filter</el-button
                  >
                </el-form-item>
            </el-row>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
// import Vue from "vue";
// import FieldFilledBy from "./FieldFilledBy";
// import Placeholder from "./Placeholder";
import { mapGetters } from "vuex";
// import VueLodash from "vue-lodash";
// import lodash from "lodash";
// Vue.use(VueLodash, {
//   name: "custom",
//   lodash: lodash,
// });
export default {
  name:"templates-formComponents-GlobalVariable",
  components: {
    "FieldFilledBy":()=>import("./FieldFilledBy"),
    "Placeholder":()=>import("./Placeholder.vue"),
  },
  computed: {
    ...mapGetters("userTypes", ["getUserTypes"]),
    ...mapGetters("companyEntities", ["getAllCompanyEntitiesData","getSingleCompanyEntity"]),
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    getValidUserTypes() {
      return (this.getUserTypes.data || []).filter(
        (userType) => userType.name != "ADMIN"
      );
    },
    getValidEntities() {
      return (this.getAllCompanyEntitiesData.data || []).filter(
        (entity) => entity.status == "ACTIVE"
      );
    },
  },
  props: ["field", "isEntity"],
  data() {
    return {
      filterNo: 1,

      Data: [],

      pageLoading: false,
      validations: [],
      globalVariableTypes: ["USERS", "ENTITY"],
      valueTypes: [
        {
          id: "manual_field",
          name: "Field",
        },
        {
          id: "global_variables",
          name: "Global Variables",
        },
      ],
      selectTypeOptions: [
        {
          name: "Single",
          value: "single",
        },
        {
          name: "Multiple",
          value: "multiple",
        },
      ],
      rowsOptions: [
        {
          title: "Folder 1",
          _id: "1",
        },
        {
          title: "Folder 2",
          _id: "2",
        },
        {
          title: "Folder 3",
          _id: "3",
        },
      ],
      selectedVariable: "",
      filterOptions: [],

      filterBy: [],
      selectedFilter: "",
      filterValue: "",
      selectedFilterField: {},
      selectedEntity: {},
      selectedEntityFields: [],
      editFilterIndex: -1,
      selectValueOptions: [],

      selectedValueByType: "",
      selectedVariableType: "",
      globalVariableSelectFilterValue: "",
      loading: false,
    };
  },

  async mounted() {
    this.field.validations.type = "OBJECT_ID";
    this.fetchUserTypes();
    await this.fetchEntities();
    if (
      this.field.global_variable_entity_type &&
      this.getAllCompanyEntitiesData.data &&
      this.getAllCompanyEntitiesData.data.length
    ) {
      // this.setEntityFields(true);
      // await this.$store.dispatch( "companyEntities/fetchSingleCompanyEntity", this.$route.params.entity_id );
      this.setEntityFields(this.field.global_variable_entity_type,true)      
    }
    await this.fetchGlobalVariables();
    // if(onUpdateField)
    // {
    //    await this.$store.dispatch("companyEntities/fetchSingleCompanyEntity",onUpdateField);
    // }
  },
  methods: {
    saveFilter(){ 
       this.field.filters=this.Data
    },
 
    async addFilter() {
      // let previousFilters = this.data;
      this.filterNo = this.filterNo + 1;
      this.Data.push({
        key: this.filterNo,
        filedKey:"",
        field: "",
        filter: "",
        option: "",
        valueType: "",
        value: "",
        fieldList: [...this.selectedEntityFields],
        filterOptions: [],
        filterList: [],
        optionsList: [...this.filterBy],
        valueTypeList: [...this.valueTypes],
        selectedVariableType: "",
        valueInputType: "input",
        selectedValueType: "manual_field",
      });
    },
    async fetchUserTypes() {
      await this.$store.dispatch("userTypes/fetchUserTypes", {
        get_all: true,
      });
    },
    async fetchEntities() {
      await this.$store.dispatch("companyEntities/getAllCompanyEntities", {
        get_all: true,
      });
    },
    onChangeGlobalVariableType() {
      if (this.field.global_variable_type == "USERS") {

        this.field.filters = [];
      }
    },
    async setEntityFields(onUpdateField,editStatus) {
        //if(this.getSingleCompanyEntity.sections[0].fields[0].filters[0]){
        //      await this.getSingleCompanyEntity.sections[0].fields[0].filters[0].map(async (element) => {
        //        this.Data = element;
        //   });
        // }
      // this.selectedVariable = "";
      // this.selectedFilter = "";
      // this.filterValue = "";
      // this.globalVariableSelectFilterValue = "";
      //  let selectedEntity = await lodash.filter(this.getAllCompanyEntitiesData.data, function(entity) { return entity._id == entityId });
      if(editStatus)
      {
        await this.$store.dispatch( "companyEntities/fetchSingleCompanyEntity", this.$route.params.entity_id );
      }
      else{
         await this.$store.dispatch( "companyEntities/fetchSingleCompanyEntity", onUpdateField );
      }
       
       if (onUpdateField != true) {
        this.field.filters = [];
      }

      let entityId = this.field.global_variable_entity_type;
      let selectedEntity = await this.getAllCompanyEntitiesData.data.find(
        (entity) => entity._id == entityId
      );
      this.selectedEntity = selectedEntity;
      this.selectedEntityFields = [];
      await selectedEntity.sections.map(async (section) => {
        if (section.fields && section.fields.length) {
          await section.fields.map(async (element) => {
            if (
             element.input_type == "SINGLE_LINE_TEXT" ||
            element.input_type == "SELECT" ||
            element.input_type == "DATE" ||
            element.input_type == "NUMBER" ||
            element.input_type == "PHONE" ||
             element.input_type == "EMAIL" 
            )
            await this.selectedEntityFields.push(element);
          });
        }
        // return;
      });
      if (this.selectedEntityFields.length) {
 
        if(this.getSingleCompanyEntity.sections &&this.getSingleCompanyEntity.sections[0]&&this.getSingleCompanyEntity.sections[0].fields&&this.getSingleCompanyEntity.sections[0].fields[0]&&this.getSingleCompanyEntity.sections[0].fields[0].filters && this.getSingleCompanyEntity.sections[0].fields[0].filters.length)
        {
          this.Data=[]
          this.getSingleCompanyEntity.sections[0].fields[0].filters.map(async (data,index)=>{
            console.log(`Filter Data:${index}`,data)
         
            this.Data.push({
                key: data.key,
                filedKey:data.filedKey,
                field: data.field,
                filter: data.filter,
                option: data.option,
                valueType: data.valueType,
                value: data.value,
                fieldList: [...this.selectedEntityFields],
                filterOptions: [...data.filterOptions],
                filterList: [...data.filterList],
                optionsList: [...this.filterBy],
                valueTypeList: [...this.valueTypes],
                selectedVariableType: "",
                valueInputType: "input",
                selectedValueType: "manual_field",
              });
          })
        }
        else{
           this.Data=[]
          this.Data.push({
          key: this.filterNo,
          filedKey:'',
          field: "",
          filter: "",
          option: "",
          valueType: "",
          value: "",
          fieldList: [...this.selectedEntityFields],
          filterOptions: [],
          filterList: [],
          optionsList: [...this.filterBy],
          valueTypeList: [...this.valueTypes],
          selectedVariableType: "",
          valueInputType: "input",
          selectedValueType: "manual_field",
        });
        }
        
      }
    },
    getFields() {
      let fields = [];
      if (this.selectedEntityFields && this.selectedEntityFields.length) {
        this.selectedEntityFields.forEach((element) => {
          if (
            element.input_type == "SINGLE_LINE_TEXT" ||
            element.input_type == "SELECT" ||
            element.input_type == "DATE" ||
            element.input_type == "NUMBER" ||
            element.input_type == "PHONE" ||
             element.input_type == "EMAIL" 
          )
            fields.push(element);
        });
      }
      return fields;
    },
    getGlobalVariableData() {
      let list = [];
      for (
        let index = 0;
        index < this.getAllGlobalVariables.data.length;
        index++
      ) {
        const element = this.getAllGlobalVariables.data[index];
        if (
          this.selectedVariableType == "SELECT" &&
          element.input_type == this.selectedVariableType
        ) {
          list.push(element);
        } else {
          if (element.input_type == this.selectedVariableType) {
            list.push(element);
          }
        }
      }
      return list;
    },
    getGlobalVariableSelectDataOptions() {
      let globalVariableData = this.getAllGlobalVariables.data.filter((x) => {
        return x._id == this.globalVariableSelectFilterValue;
      });
      return globalVariableData[0] && globalVariableData[0].options
        ? globalVariableData[0].options
        : [];
    },
    getValueOfGlobalVariable(item) {
      return item.label;
    },
    async setFilterField(e, index) {
      let selectedData = this.Data[index];
      // let selectedEntity = await lodash.filter(
      //   selectedData.fieldList,
      //   function (entity) {
      //     return entity.key == e;
      //   }
      // );
      let selectedEntity = selectedData.fieldList.filter(entity => entity.key == e);

      if (selectedEntity.length) {
        selectedData.fieldKey=selectedEntity[0].key
        let filterBy = [];

        let filterOptions = [];
        if (selectedEntity[0].input_type == "SINGLE_LINE_TEXT") {
          filterOptions = [];
          filterOptions.push("=");
            filterBy = ["AND", "OR"];
        } else if (selectedEntity[0].input_type == "SELECT") {
          filterOptions = [];
          filterOptions.push("=");
            filterBy = ["AND", "OR"];
        } else if (
          selectedEntity[0].input_type == "NUMBER" ||
          selectedEntity[0].input_type == "DATE" ||
            selectedEntity[0].input_type == "PHONE" ||
          selectedEntity[0].input_type == "EMAIL" 
        ) {
          filterOptions = [];

          filterOptions = ["<", ">", "="];

          this.filterBy = ["AND", "OR"];
          filterBy = ["AND", "OR"];
        }

        selectedData.filterOptions = filterOptions;
        this.filterBy = ["AND", "OR"];
        selectedData.optionsList = filterBy;
        if (selectedEntity[0].input_type == "DATE") {
          selectedData.valueInputType = "date";
        } else if (selectedEntity[0].input_type == "SELECT") {
          selectedData.valueInputType = "select";
        } else {
          selectedData.valueInputType = "input";
        }

        this.Data[index] = selectedData;
      }
    },
    setFilter() {
      this.selectedVariableType = "manual_field";
    },
    async setValueType(e, index) {
      console.log("selectedValueType: ", index, e);
      this.filterValue = "";
    },

    // saveFilter() {
    //   if (!this.field?.hasOwnProperty('filters')) {
    //     this.field["filters"] = [];
    //   }
    //   if (this.editFilterIndex > -1) {
    //     this.field.filters[this.editFilterIndex] =
    //       this.selectedFilterfield.key +
    //       "" +
    //       this.selectedFilter +
    //       "" +
    //       this.filterValue;
    //   } else {
    //     this.field.filters.push(
    //       this.selectedFilterfield.key +
    //         "" +
    //         this.selectedFilter +
    //         "" +
    //         this.filterValue
    //     );
    //   }
    //   this.selectedFilterfield = {};
    //   this.selectedVariable = "";
    //   this.selectedVariableType = "";
    //   this.selectedFilter = "";
    //   this.filterValue = "";
    //   this.filterOptions = "";
    //   this.globalVariableSelectFilterValue = "";
    //   this.editFilterIndex = -1;
    // },
    // editFilter(index) {
    //   this.editFilterIndex = index;
    //   let filter = this.field.filters[index];
    //   let fields = [];
    //   if (filter.indexOf("<") > -1) {
    //     fields = filter.split("<");
    //     this.selectedFilter = "<";
    //   } else if (filter.indexOf(">") > -1) {
    //     fields = filter.split(">");
    //     this.selectedFilter = ">";
    //   } else if (filter.indexOf("=") > -1) {
    //     fields = filter.split("=");
    //     this.selectedFilter = "=";
    //   }
    //   this.selectedFilterfield = this.selectedEntityFields.filter(
    //     (x) => x.key == fields[0]
    //   )[0];
    //   this.selectedVariable = this.selectedFilterfield.label;
    //   this.filterValue = fields[1];
    //   this.selectedEntityFields.forEach((x) => {
    //     if (x.key == fields[0]) {
    //       if (x.input_type == "SINGLE_LINE_TEXT" || x.input_type == "SELECT") {
    //         this.filterOptions = ["="];
    //       } else if (x.input_type == "NUMBER" || x.input_type == "DATE") {
    //         this.filterOptions = ["<", ">", "="];
    //       }
    //     }
    //   });
    // },
    deleteFilter(index) {
      this.$confirm("Are you sure. Continue?", "Delete Filter", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
         this.Data.splice(index, 1);
          this.loading = false;
        })
        .catch(() => {});
    },
    async fetchGlobalVariables() {
      try {
        await this.$store.dispatch("globalVariables/fetchGlobalVariables", {
          get_all: true,
        });
      } catch (error) {
        console.log("fetchGlobalVariables",error);
      }
    },
    onChangeRepresentation() {
      if (this.field.target_relationship_representation == "UNI-DIRECTION") {
        this.field.relationship_direction = 1;
      } else {
        this.field.relationship_direction = 2;
      }
    },
   
  },
};
</script>

<style lang="scss">
.btnFloat {
  float: right;
 
  color: white;
  background-color: #a0cfff;
}

.el-form-item {
  margin-bottom: 1px !important;
}

.data-table {
  margin: 0em;
  display: block;
  max-width: auto;
  .each-cell {
    background-color: #ffffff;
    padding: 10px 15px;
    border-bottom: 1px solid #efefef;
    font-size: 1.15em;
    display: grid;
    grid-template-columns: 60px auto 100px;
  }
}

.table-scrollable {
  max-height: calc(100vh - 470px);
  overflow-y: auto;
}
</style>
<style lang="scss">
.add-global-variable-container {
  display: flex  !important;
  flex-direction: row;
  @media (max-width: 600px) {
    flex-direction: column;
  }
  .add-global-variable-container-right-side {
    margin-left: 20px;
    @media (max-width: 600px) {
      margin-left: 0px;
      .el-divider--horizontal {
        display: none;
      }
    }
  }
  hr.hr-for-mobile {
    display: none;
  }
  @media (max-width: 600px) {
    hr.hr-for-non-mobile {
      display: none;
    }
    hr.hr-for-mobile {
      margin-top: 45px;
      display: block;
    }
  }
  .add-global-variable-container-left-side {
    .form-left {
      @media (min-width: 600px) {
        padding-right: 20px;
      }
    }
  }
}
</style>